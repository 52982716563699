import(/* webpackMode: "eager", webpackExports: ["FloatingUI"] */ "/app/app/components/FloatingUI.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VirtualConsoleLog"] */ "/app/app/components/VirtualConsoleLog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/app/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/azure/ApplicationInsights.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DictionaryProvider"] */ "/app/packages/dictionary/DictionaryContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SignalRProvider"] */ "/app/packages/signalr/SignalRContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TimeoutModal"] */ "/app/packages/ui/TimeoutModal.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/ui.css");
